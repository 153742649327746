import { ReactNode, useEffect } from 'react';
import Modal from 'react-modal';

import { Heading } from '@/components/dom/text-elements';
import Button from '@/components/global/button';
import Icon from '@/components/global/icon';
import cn from '@/lib/cn';

type ModalProps = {
    header?: ReactNode;
    isOpen: boolean;
    onRequestClose: () => void;
    children: ReactNode;
    allowClose?: boolean;
    allowCloseOnShortcut?: boolean;
    size?: '1/3' | '1/2' | '3/4' | '2/3' | 'full';
    closeText?: string;
    allowScroll?: boolean;
    className?: string;
    headerClassName?: string;
};

const BaseModal = ({
    header = '',
    isOpen,
    onRequestClose,
    allowClose = true,
    allowCloseOnShortcut = true, // escape or overlay click
    children,
    size = '1/2',
    closeText = '',
    allowScroll = true,
    className,
    headerClassName,
}: ModalProps) => {
    useEffect(() => {
        Modal.setAppElement('#root');
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            shouldCloseOnOverlayClick={allowClose && allowCloseOnShortcut}
            shouldCloseOnEsc={allowClose && allowCloseOnShortcut}
            portalClassName="absolute z-1000 bg-white w-full"
            className={cn(
                'absolute w-1/2 bg-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[400px] rounded-md shadow-md',
                {
                    'w-1/2': size === '1/2',
                    'w-1/3': size === '1/3',
                    'w-2/3': size === '2/3',
                    'w-3/4': size === '3/4',
                    'w-full': size === 'full',
                },
                className
            )}
            overlayClassName="fixed bg-black bg-opacity-80 top-0 left-0 right-0 bottom-0"
        >
            <div className="relative">
                <Heading
                    importance={4}
                    className={cn('p-4 pb-0 mb-1 font-alt-riveria-bold', headerClassName)}
                >
                    {header}
                </Heading>
                {allowClose && (
                    <Button
                        type="action"
                        onClick={() => onRequestClose()}
                        color="transparent"
                        className="flex items-center absolute top-1/2 right-0 -translate-y-1/2 z-10"
                    >
                        {closeText}
                        <Icon
                            type="close"
                            size="sm"
                            className="ml-2 mt-1"
                            color="blueYves"
                        />
                    </Button>
                )}
            </div>
            <div className={cn({ 'max-h-[70vh] overflow-y-auto px-2': allowScroll === true })}>{children}</div>
        </Modal>
    );
};

export default BaseModal;
