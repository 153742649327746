'use client';

import { useEffect, useState } from 'react';

import { debounce } from '@/helpers/debounce';

export interface UseScrollingListenerState {
    isScrolling: boolean;
    x: number;
    y: number;
    lastX: number;
    lastY: number;
}

export const useScrollListener = (): UseScrollingListenerState => {
    const [data, setData] = useState({
        isScrolling: false,
        lastX: 0,
        lastY: 0,
        x: 0,
        y: 0,
    });

    // set up event listeners
    useEffect(() => {
        const handleScroll = debounce(() => {
            setData((last) => {
                return {
                    isScrolling: true,
                    lastX: last.x,
                    lastY: last.y,
                    x: window.scrollX,
                    y: window.scrollY,
                };
            });
        }, 150);

        const handleScrollEnd = debounce(() => {
            setData((last) => {
                return {
                    ...last,
                    isScrolling: false,
                };
            });
        }, 150);

        handleScroll();
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('scrollend', handleScrollEnd);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('scrollend', handleScrollEnd);
        };
    }, []);

    return data;
};
