import { GraphQLClient, Variables } from 'graphql-request';
import { gql } from 'graphql-request';

import { AbortSignal, GQL_CLIENT } from '@/lib/graphql';
import { AnalystOpenQueryResult, Maybe, Scalars } from '@/queries/graphql-types';
import { InstrumentTypeValue } from '@/types/instrument';

type LatestFundamentalsType = {
    businessDescription: string;
};

export type FundamentalType = {
    name: string;
} & (
    | {
          __typename: 'InstrumentFundamentalDate';
          dateValue: Optional<string>;
          stringValue?: never;
          decimalValue?: never;
      }
    | {
          __typename: 'InstrumentFundamentalString';
          stringValue: Optional<string>;
          dateValue?: never;
          decimalValue?: never;
      }
    | {
          __typename: 'InstrumentFundamentalDecimal';
          decimalValue: Optional<string>;
          dateValue?: never;
          stringValue?: never;
      }
);

type InstrumentType = {
    id: string;
    symbol: string;
    exchange: string;
    companyName: string;
    instrumentType: InstrumentTypeValue;
    numArticles: number;
    primaryRegionCountryCode: string;
    latestFundamentals: LatestFundamentalsType;
    csvFundamentals: Array<FundamentalType>;
    businessFundamentals: Array<FundamentalType>;
};

type InferredReferenceInstrumentType = {
    instrumentCompanyName?: Optional<string>;
    instrumentId: Optional<string>;
    userQuerySubstring?: Optional<string>;
};

type InstrumentMatchResultBase = {
    matchScore: number;
    isStrongMatch: boolean;
    isPartialMatch: boolean;
};

export type InstrumentMatchResultType = InstrumentMatchResultBase & {
    __typename: 'ThemeInstrumentMatchResult';
    instrument: InstrumentType;
    reason: Optional<string>;
    citation: Optional<string>;
};

export type ObfuscatedInstrumentMatchResultType = InstrumentMatchResultBase & {
    __typename: 'ObfuscatedThemeInstrumentMatchResult';
};

// TODO: find a way to have this as `string`, but still properly `Exclude`
type ValidFilterTypeNames =
    | 'InstrumentSearchAndPredicate'
    | 'InstrumentSearchCombinedQueryPredicate'
    | 'InstrumentSearchComparisonPredicate'
    | 'InstrumentSearchInPredicate'
    | 'InstrumentSearchMatchPredicate'
    | 'InstrumentSearchPhraseSimilarityPredicate'
    | 'InstrumentSearchOrPredicate';

export type AnalystFilter = AnalystAndFilterPredicate;

type AnalystAndFilterPredicate = {
    __typename: 'InstrumentSearchAndPredicate';
    predicates: Array<AnalystFilterPredicate>;
};

export type AnalystFilterPredicate = { asString?: string } & (
    | {
          __typename: 'InstrumentSearchComparisonPredicate';
          field: string;
          value: string;
          comparator: 'LT' | 'LTE' | 'GT' | 'GTE';
      }
    | {
          __typename: 'InstrumentSearchInPredicate';
          field: string;
          values: Array<string>;
      }
    | AnalystAndFilterPredicate
    | {
          __typename: Exclude<
              ValidFilterTypeNames,
              'InstrumentSearchComparisonPredicate' | 'InstrumentSearchInPredicate' | 'InstrumentSearchAndPredicate'
          >;
      }
);

type AnalystFilterInput = {
    and: {
        filters: Array<
            | { in: { field: string; values: Array<string> } }
            | {
                  comparison: {
                      comparator: string;
                      field: string;
                      value: string | number;
                  };
              }
        >;
    };
};

export type AnalystThemeResultType = {
    requestId: string;
    userQuery: string;
    isInstrumentMatchesFullyEvaluated: boolean;
    isCompleted: boolean;
    isStockScreener: boolean;
    theme: Optional<string>;
    name: Optional<string>;
    analysis: Optional<string>;
    analysisSubcategories?: Maybe<Scalars['String']['output']>;
    analysisSummary?: Maybe<Scalars['String']['output']>;
    isAnalysisCompleted: boolean;
    fieldsOfInterest: Optional<Array<string>>;
    inferredReferenceInstruments: Optional<Array<InferredReferenceInstrumentType>>;
    authenticatedTopMatchResult: Optional<InstrumentMatchResultType>;
    authenticatedInstrumentResults: Optional<Array<InstrumentMatchResultType | ObfuscatedInstrumentMatchResultType>>;
    filter: Optional<AnalystFilter>;
    progressPercentage: number;
    processingMessage: Optional<string>;
};

export const CSV_FUNDAMENTALS = [
    'sector_name',
    'industry_name',
    'market_capitalization_usd',
    'gross_income_margin',
    'one_year_annual_revenue_growth_rate',
    'enterprise_value_revenue_ratio',
    'ebitda_margin',
];

export const BUSINESS_FUNDAMENTALS = [
    'market_capitalization_usd',
    'one_year_annual_revenue_growth_rate',
    'earnings_per_share',
    'price_earnings_ratio',
    'float_as_percentage_of_shares_outstanding_usd',
    'return_on_total_capital',
];

export const COMPANY_FUNDAMENTALS_FOR_EXPORT = [
    'market_capitalization_usd',
    'gross_income_margin',
    'one_year_annual_revenue_growth_rate',
    'enterprise_value_revenue_ratio',
    'ebitda_margin',
    'market_capitalization_usd',
    'earnings_per_share',
    'price_earnings_ratio',
    'return_on_total_capital',
];

const TOP_MATCH_FRAGMENT_NAME = gql`TopMatchResultFields`;
const TOP_MATCH_FRAGMENT = gql`
    fragment ${TOP_MATCH_FRAGMENT_NAME} on ThemeInstrumentMatchResult {
        reason
        citation
        instrument {
            id
            symbol
            exchange
            companyName
            instrumentType
            numArticles
            businessFundamentals: fundamentals(fieldNames: $businessFundamentalFieldNames) {
                name
                __typename
                ... on InstrumentFundamentalDate {
                    dateValue
                }
                ... on InstrumentFundamentalDecimal {
                    decimalValue
                }
                ... on InstrumentFundamentalString {
                    stringValue
                }
            }
            latestFundamentals {
                businessDescription
            }
        }
    }
`;

const INSTRUMENT_RESULT_FRAGMENT_NAME = gql`InstrumentResultFields`;
const INSTRUMENT_RESULT_FRAGMENT = gql`
    fragment ${INSTRUMENT_RESULT_FRAGMENT_NAME} on ThemeInstrumentMatchResultBase {
        __typename
        matchScore
        isStrongMatch
        isPartialMatch
        ... on ThemeInstrumentMatchResult {
            reason
            citation
            instrument {
                id
                symbol
                exchange
                companyName
                instrumentType
                numArticles
                primaryRegionCountryCode
                latestFundamentals {
                    businessDescription
                }
                csvFundamentals: fundamentals(fieldNames: $csvFundamentalsFieldNames) {
                    name
                    __typename
                    ... on InstrumentFundamentalDate {
                        dateValue
                    }
                    ... on InstrumentFundamentalDecimal {
                        decimalValue
                    }
                    ... on InstrumentFundamentalString {
                        stringValue
                    }
                }
            }
        }
    }
`;

export const ANALYST_THEME_RESULT_FRAGMENT_NAME = gql`AnalystThemeFields`;
export const ANALYST_THEME_RESULT_FRAGMENT = gql`
    fragment ${ANALYST_THEME_RESULT_FRAGMENT_NAME} on AnalystThemeResult {
        requestId
        userQuery
        isInstrumentMatchesFullyEvaluated
        isCompleted
        isStockScreener
        theme
        name
        analysis
        analysisSubcategories
        analysisSummary
        isAnalysisCompleted
        fieldsOfInterest
        inferredReferenceInstruments {
            instrumentId
        }
        authenticatedTopMatchResult {
            ...${TOP_MATCH_FRAGMENT_NAME}
        }
        authenticatedInstrumentResults {
            ...${INSTRUMENT_RESULT_FRAGMENT_NAME}
        }
        filter {
            __typename
            ... on InstrumentSearchAndPredicate {
                predicates {
                    __typename
                    ... on InstrumentSearchPredicateInterface {
                        asString
                    }
                    ... on InstrumentSearchComparisonPredicate {
                        field
                        value
                        comparator
                    }
                    ... on InstrumentSearchInPredicate {
                        field
                        values
                    }
                    __typename
                    ... on InstrumentSearchAndPredicate {
                        predicates {
                            __typename
                            ... on InstrumentSearchPredicateInterface {
                                asString
                            }
                            ... on InstrumentSearchComparisonPredicate {
                                field
                                value
                                comparator
                            }
                            ... on InstrumentSearchInPredicate {
                                field
                                values
                            }
                        }
                    }
                }
            }
        }
        progressPercentage
        processingMessage
    }
`;

export const analystThemeSearch = async (
    userQuery: string,
    userFilter: AnalystFilterInput | null,
    { gqlClient, abortController }: { gqlClient?: GraphQLClient; abortController?: AbortController } = {}
): Promise<{ requestId: string }> => {
    const query = gql`
        query AnalystThemeSearch($userQuery: String!, $userFilter: InstrumentSearchFilterInput) {
            analystThemeSearch(userQuery: $userQuery, userFilter: $userFilter) {
                requestId
            }
        }
    `;

    const response: { analystThemeSearch: AnalystThemeResultType } = await (gqlClient || GQL_CLIENT).request(
        buildRequest(query, { userFilter, userQuery }, abortController)
    );

    return response.analystThemeSearch;
};

export const refreshAnalystThemeResult = async (
    requestId: string,
    { gqlClient, abortController }: { gqlClient?: GraphQLClient; abortController?: AbortController } = {}
): Promise<AnalystThemeResultType> => {
    const query = gql`
        ${TOP_MATCH_FRAGMENT}
        ${INSTRUMENT_RESULT_FRAGMENT}
        ${ANALYST_THEME_RESULT_FRAGMENT}
        query AnalystThemeResult(
            $requestId: ID!,
            $csvFundamentalsFieldNames: [String!]!
            $businessFundamentalFieldNames: [String!]!
        ) {
            analystThemeResult(requestId: $requestId) {
                ...${ANALYST_THEME_RESULT_FRAGMENT_NAME}
            }
        }
    `;

    const response: { analystThemeResult: AnalystThemeResultType } = await (gqlClient || GQL_CLIENT).request(
        buildRequest(
            query,
            {
                businessFundamentalFieldNames: BUSINESS_FUNDAMENTALS,
                csvFundamentalsFieldNames: CSV_FUNDAMENTALS,
                requestId,
            },
            abortController
        )
    );

    return response.analystThemeResult;
};

const buildRequest = (query: string, variables: Variables, abortController?: AbortController) => {
    return {
        document: query,
        variables,
        ...(abortController ? { signal: abortController.signal as AbortSignal } : {}),
    };
};

const ANALYST_OPEN_QUERY = gql`
    mutation ($query: String!) {
        analystOpenQuery(query: $query) {
            __typename
            ... on AnalystThemeResult {
                requestId
            }
            ... on AnalystInstrumentAnalysisQuery {
                instrument {
                    id
                    symbol
                    exchange
                }
                query
            }
        }
    }
`;

export const getAnalystOpenQuery = async (userQuery: string): Promise<AnalystOpenQueryResult> => {
    const { analystOpenQuery }: { analystOpenQuery: AnalystOpenQueryResult } = await GQL_CLIENT.request({
        document: ANALYST_OPEN_QUERY,
        variables: { query: userQuery },
    });
    return analystOpenQuery;
};
