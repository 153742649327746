import Button from '@/components/global/button';
import Icon, { IconMap, IconProps } from '@/components/global/icon';
import cn from '@/lib/cn';

interface IconButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    onTouchEnd: React.TouchEventHandler<HTMLButtonElement>;
    iconType: keyof typeof IconMap;
    className?: string;
    title?: string;
    tabIndex?: number;
    iconProps?: {
        className?: string;
        iconClassName?: string;
        size?: IconProps['size'];
        fill?: IconProps['fill'];
        stroke?: IconProps['stroke'];
        color?: IconProps['color'];
    };
}

const IconButton = ({ onClick, iconType, className, iconProps, tabIndex, title }: IconButtonProps) => {
    return (
        <Button
            tabIndex={tabIndex}
            onClick={onClick}
            type="action"
            color="transparent"
            title={title}
            className={cn(
                'hover:opacity-70 transition-opacity text-transparent p-0 bg-transparent border-none',
                className
            )}
        >
            <Icon
                color="inherit"
                className="text-analyst-purple border-0"
                type={iconType}
                stroke="none"
                size="xxl"
                {...iconProps}
            />
        </Button>
    );
};

export default IconButton;
