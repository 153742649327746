import NextLink, { LinkProps } from 'next/link';
import React, { ReactNode } from 'react';

import cn from '@/lib/cn';

const defaultStyles = cn('group transition-colors text-brand-blue-yves no-underline hover:text-analyst-dark-lavender');

export type NavigationLinkProps = LinkProps & {
    target?: string;
    children: ReactNode;
    className?: string;
    tabIndex?: number;
    title?: string;
    rel?: string;
};

export const NavigationLink = ({ children, className, ...passThroughProps }: NavigationLinkProps) => {
    return (
        <NextLink
            className={cn(defaultStyles, className)}
            {...passThroughProps}
        >
            {children}
        </NextLink>
    );
};

type ActionLinkProps = {
    children: ReactNode;
    className?: string;
    onClick: React.MouseEventHandler<HTMLAnchorElement>;
    title?: string;
};

export const ActionLink = ({ children, className, onClick, title }: ActionLinkProps) => {
    return (
        <a
            className={cn(defaultStyles, className)}
            {...{ onClick, title }}
        >
            {children}
        </a>
    );
};
