import { gql } from 'graphql-request';
import { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';

import { FieldError, Label, SubmitButton, TextInput } from '@/components/dom/form-elements';
import { useGlobalState } from '@/components/global/global-state';
import { GQL_CLIENT } from '@/lib/graphql';
import { CURRENT_USER_FIELDS, CurrentUserType } from '@/queries/current-user';

const OTP_QUERY = gql`
  mutation CreateUserSession($loginAttemptId: String!, $challengeToken: String!) {
      createUserSession(input: { loginAttemptId: $loginAttemptId, challengeToken: $challengeToken }) {
          __typename
          ... on UserSession {
              user {
                  ${CURRENT_USER_FIELDS}
              }
              sessionToken
              sessionExpiresAt
          }
          ... on Error {
              message
          }
      }
  }
`;

interface OTPFormProps {
    loginAttemptId: string;
    onLoginSuccess?: (user: CurrentUserType) => void;
    submitButtonCopy: string;
}

export const OTPForm = ({ loginAttemptId, onLoginSuccess, submitButtonCopy }: OTPFormProps) => {
    const { setGlobalState } = useGlobalState();
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const onOTPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOtp(event.target.value);
        setOtpError('');
    };
    const submitOtpForm = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);

        const variables = {
            challengeToken: otp,
            loginAttemptId,
        };

        const data: {
            createUserSession:
                | {
                      __typename: 'UserSession';
                      user: CurrentUserType;
                      sessionToken: string;
                      sessionExpiresAt: string;
                  }
                | {
                      __typename: 'Error';
                      message: string;
                  };
        } = await GQL_CLIENT.request(OTP_QUERY, variables);

        const result = data.createUserSession;
        const success = result.__typename === 'UserSession';

        if (success) {
            setGlobalState((prev) => {
                return {
                    ...prev,
                    currentUser: result.user,
                };
            });
            toast.success('You’re now signed in.');

            onLoginSuccess?.(result.user);
        } else {
            setOtpError(result.message);
        }
        setIsSubmitting(false);
    };

    return (
        <form onSubmit={submitOtpForm}>
            <div className="mb-6">
                <Label
                    text="One-time passcode"
                    labelCopyClassName="text-left text-analyst-darker-gray font-brand-md mb-2 text-base"
                >
                    <TextInput
                        fieldClassName="w-full rounded-full px-3 py-2"
                        value={otp}
                        onChange={onOTPChange}
                        required={true}
                        spellCheck={false}
                        placeholder="ex. 123456"
                    />
                </Label>
                <FieldError message={otpError} />
            </div>
            <SubmitButton
                isDisabled={isSubmitting}
                value={submitButtonCopy}
                roundedCorners="full"
                className="font-brand-md mx-0 w-full"
                color="primary-gradient"
            />
        </form>
    );
};
