'use client';

import { createContext, useContext, useState } from 'react';

import { OpenWithTypes } from '@/components/forms/login-signup-form';
import { useGlobalState } from '@/components/global/global-state';
import { LoginSignupModal } from '@/components/modals/login-signup-modal';
import { Nullable } from '@/types/nullable';

interface ILoggedInGateContext {
    closeLoginModal: () => void;
    isLoggedIn: boolean;
    modalType: OpenWithTypes;
    openLoginModal: (evt: React.MouseEvent<Element, MouseEvent>) => void;
    showLoginModal: boolean;
}

interface LoggedInGateProviderProps {
    children: React.ReactNode;
}

export const LoggedInGateContext = createContext<Nullable<ILoggedInGateContext>>(null);

export const LoggedInGateProvider = ({ children }: LoggedInGateProviderProps) => {
    const { globalState } = useGlobalState();
    const { currentUser, currentUserLoading } = globalState;
    const isLoggedIn = !!currentUser && !currentUserLoading;
    const [showLoginModal, setShowLoginModalState] = useState<boolean>(false);
    const [modalType, setModalType] = useState<OpenWithTypes>(OpenWithTypes.SIGNUP);
    const openLoginModal = (evt: React.MouseEvent<Element, MouseEvent>) => {
        evt.preventDefault();
        setModalType(OpenWithTypes.LOGIN);
        setShowLoginModalState(true);
    };
    const closeLoginModal = () => setShowLoginModalState(false);
    const values: ILoggedInGateContext = {
        closeLoginModal,
        isLoggedIn,
        modalType,
        openLoginModal,
        showLoginModal,
    };

    return (
        <LoggedInGateContext.Provider value={values}>
            {children}
            <LoginSignupModal
                isOpen={showLoginModal}
                openWith={modalType}
                closeFn={closeLoginModal}
            />
        </LoggedInGateContext.Provider>
    );
};

export const useLoggedInGate = () => {
    const context: Nullable<ILoggedInGateContext> = useContext(LoggedInGateContext);

    if (!context) {
        throw new Error('useLoggedInGate must be used within a LoggedInGateProvider');
    }

    return context;
};
