import '@/styles/index.css';

import { NextComponentType, NextPageContext } from 'next';
import { AppProps as NextAppProps } from 'next/app';

import { CurrentOrganizationProvider } from '@/components/global/current-organization';
import CurrentUser from '@/components/global/current-user';
import { FeatureFlagsProvider } from '@/components/global/feature-flags';
import GaScript from '@/components/global/ga-script';
import { GlobalStateProvider } from '@/components/global/global-state';
import {
    AdminLayout,
    AnalystLayout,
    EmptyLayout,
    IndexBuilderLayout,
    NewLayoutNoCondensedLogo,
    RedesignLayout,
} from '@/components/global/layout';
import Notifications from '@/components/global/notifications';
import SiBScript from '@/components/global/sib-script';
import { MainContentProvider } from '@/components/pages/GlobalMainContent';
import { LoggedInGateProvider } from '@/components/providers/LoggedInGate';
import ThematicPostHogProvider from '@/components/providers/Posthog';

export interface AppPropsType extends NextAppProps {
    Component: NextComponentType<NextPageContext, object> & { layout: string; layoutProps?: object };
}

const altLayouts = new Map([
    ['indexBuilder', IndexBuilderLayout],
    ['admin', AdminLayout],
    ['empty', EmptyLayout],
    ['analyst', AnalystLayout],
    ['redesign', RedesignLayout],
    ['newLayoutNoCondensedLogo', NewLayoutNoCondensedLogo],
]);

export default function MyApp({ Component, pageProps }: AppPropsType) {
    const Layout = altLayouts.get(Component.layout) ?? RedesignLayout;
    const layoutProps = Component.layoutProps ?? {};

    return (
        <GlobalStateProvider>
            <CurrentOrganizationProvider>
                <CurrentUser>
                    <LoggedInGateProvider>
                        <FeatureFlagsProvider>
                            <ThematicPostHogProvider>
                                <MainContentProvider>
                                    <Layout {...layoutProps}>
                                        <GaScript />
                                        <SiBScript />
                                        <Notifications />
                                        <Component {...pageProps} />
                                    </Layout>
                                </MainContentProvider>
                            </ThematicPostHogProvider>
                        </FeatureFlagsProvider>
                    </LoggedInGateProvider>
                </CurrentUser>
            </CurrentOrganizationProvider>
        </GlobalStateProvider>
    );
}
