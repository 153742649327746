import cn from '@/lib/cn';

export enum ContainerSize {
    sm = 'sm',
    md = 'md',
    none = 'none',
    lg = 'lg',
    xl = 'xl',
    '2xl' = '2xl',
    '3xl' = '3xl',
    '4xl' = '4xl',
    '5xl' = '5xl',
    '6xl' = '6xl',
    '7xl' = '7xl',
    '8xl' = '8xl',
}

const sizeMap: {
    [key in ContainerSize]: string;
} = {
    [ContainerSize.sm]: 'max-w-sm',
    [ContainerSize.md]: 'max-w-md',
    [ContainerSize.none]: 'max-w-none',
    [ContainerSize.lg]: 'max-w-lg',
    [ContainerSize.xl]: 'max-w-xl',
    [ContainerSize['2xl']]: 'max-w-2xl',
    [ContainerSize['3xl']]: 'max-w-3xl',
    [ContainerSize['4xl']]: 'max-w-4xl',
    [ContainerSize['5xl']]: 'max-w-5xl',
    [ContainerSize['6xl']]: 'max-w-6xl',
    [ContainerSize['7xl']]: 'max-w-7xl',
    [ContainerSize['8xl']]: 'max-w-8xl',
};

interface ContainerProps {
    children: React.ReactNode;
    className?: string;
    size?: ContainerSize;
}

export const Container = ({ children, className, size = ContainerSize.xl }: ContainerProps) => {
    return (
        <div className={cn('w-full mx-auto px-4 md:px-6 lg:px-10 xl:px-20 xxl:px-0 max-w', sizeMap[size], className)}>
            {children}
        </div>
    );
};
