'use client';

import Link, { LinkProps } from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import Button, { ActionButtonProps } from '@/components/global/button';
import { useGlobalState } from '@/components/global/global-state';
import Icon, { IconMap } from '@/components/global/icon';
import { ActionLink } from '@/components/global/link';
import SignupLoginButtonModal from '@/components/global/signup-login-button-modal';
import ThematicLogoLink from '@/components/global/thematic-logo-link';
import UserProfileDropdown from '@/components/global/user-profile-dropdown';
import IconButton from '@/components/ui/IconButton';
import useOutsideClick from '@/helpers/hooks/useOutsideClick';
import { useScrollListener } from '@/helpers/hooks/useScrollListener';
import cn from '@/lib/cn';
import { MAIN_NAV_LINKS } from '@/lib/constants/navigation';
import { Nullable } from '@/types/nullable';

interface BaseLink {
    label: React.ReactNode;
    tabIndex?: number;
    className?: string;
    title?: string;
}
type SubMenuLinkProps = BaseLink & { href: string };
type AccordionNavLinkProps = BaseLink & {
    subMenu: Array<SubMenuLinkProps>;
    iconType?: keyof typeof IconMap;
};
type MainNavLinkType = SubMenuLinkProps | AccordionNavLinkProps;

type MainNavLinkInnerWrapperProps = LinkProps & {
    className?: string;
    children: React.ReactNode;
};

const MainNavLink = ({ children, className, ...linkProps }: MainNavLinkInnerWrapperProps) => {
    return (
        <Link
            className={cn(
                'tracking-wider text-analyst-white flex items-center gap-2 text-base whitespace-nowrap hover:text-analyst-lavender transition-colors',
                className
            )}
            {...linkProps}
        >
            {children}
        </Link>
    );
};

type DropdownTriggerProps = Omit<ActionButtonProps, 'type'> & {
    children: React.ReactNode;
    onClick: () => void;
    className?: string;
    iconType?: keyof typeof IconMap;
};

const DropdownTrigger = ({ children, className, onClick, iconType, ...buttonProps }: DropdownTriggerProps) => {
    return (
        <Button
            type="action"
            color="transparent"
            onClick={onClick}
            className={cn(
                'group p-0 tracking-wider text-analyst-white hover:text-analyst-lavender transition-colors text-base flex items-center gap-2 whitespace-nowrap',
                className
            )}
            {...buttonProps}
        >
            {iconType && (
                <Icon
                    type={iconType}
                    size="lg"
                    className="text-analyst-white flex items-center justify-center group-hover:text-analyst-lavender transition-colors"
                    iconClassName="w-full"
                />
            )}
            {children}
            <Icon
                type="caretDown"
                size="base"
                className="text-analyst-white flex items-center justify-center group-hover:text-analyst-lavender transition-colors"
                iconClassName="w-full"
            />
        </Button>
    );
};

type MenuLinkProps = LinkProps & {
    className?: string;
    label: React.ReactNode;
};

interface DropdownMenuProps {
    trigger: React.ReactNode;
    className?: string;
    iconType?: keyof typeof IconMap;
    menuLinks: Array<MenuLinkProps>;
}

const DropdownMenu = ({ trigger, className, iconType, menuLinks }: DropdownMenuProps) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpenState] = useState(false);
    const showDropdownMenu = () => setIsOpenState(true);
    const hideDropdownMenu = () => setIsOpenState(false);
    const toggleDropdownMenu = () => setIsOpenState(!isOpen);
    const { isScrolling, y, lastY } = useScrollListener();

    useEffect(() => {
        if (y !== lastY && isScrolling && isOpen) {
            hideDropdownMenu();
        }
    }, [isOpen, isScrolling, lastY, y]);

    useOutsideClick(dropdownRef, hideDropdownMenu);

    return (
        <div
            ref={dropdownRef}
            onMouseOver={showDropdownMenu}
            onMouseOut={hideDropdownMenu}
            className={cn('relative', className)}
        >
            <DropdownTrigger
                iconType={iconType}
                onClick={toggleDropdownMenu}
            >
                {trigger}
            </DropdownTrigger>

            <div
                className={cn('hidden opacity-0 transition-all absolute top-full left-0 pt-6 rounded-xl', {
                    'block opacity-100 transition-all z-10': isOpen,
                })}
            >
                <menu className={cn('flex flex-col min-w-fit bg-analyst-white px-2 py-4 shadow-lg rounded-xl')}>
                    {menuLinks.map(({ label, ...linkProps }, index: number) => {
                        return (
                            <Link
                                key={index}
                                {...linkProps}
                                className={cn(
                                    'text-analyst-gray font-brand-md px-4 py-1.5 hover:text-thematic-purple transition-colors rounded-full hover:bg-analyst-lavender cursor-default whitespace-nowrap',
                                    { 'cursor-pointer': isOpen },
                                    linkProps.className
                                )}
                            >
                                {label}
                            </Link>
                        );
                    })}
                </menu>
            </div>
        </div>
    );
};

interface AccordionMenuProps {
    iconType?: keyof typeof IconMap;
    label: React.ReactNode;
    subMenuItems: Array<SubMenuLinkProps>;
    onLinkClick?: () => void;
}

const AccordionMenu = ({ iconType, label, subMenuItems, onLinkClick }: AccordionMenuProps) => {
    const pathname = usePathname();
    const { push } = useRouter();
    const [isOpen, setIsOpenState] = useState(false);
    const toggleDropdown = () => setIsOpenState(!isOpen);
    const onSubMenuLinkClick = (href: string) => {
        push(href);
        onLinkClick?.();
    };

    return (
        <div className="flex flex-col items-stretch">
            <DropdownTrigger
                iconType={iconType}
                onClick={toggleDropdown}
                className={cn('justify-between', { 'mb-3': isOpen })}
            >
                {label}
            </DropdownTrigger>

            <div
                className={cn('grid grid-rows-animate-height-closed transition-all', {
                    'grid-rows-animate-height-open gap-2 transition-all': isOpen,
                })}
            >
                <div className="overflow-hidden flex flex-col items-start gap-2 transition-all">
                    {subMenuItems.map(({ label, href, ...linkProps }, index: number) => (
                        <ActionLink
                            key={index}
                            {...linkProps}
                            onClick={() => onSubMenuLinkClick(href)}
                            className={cn(
                                'text-analyst-white font-brand-md px-4 py-1.5 hover:text-analyst-white transition-colors rounded-full hover:bg-analyst-gray/30 cursor-default whitespace-nowrap',
                                {
                                    'bg-analyst-gray/30 transition-colors': pathname?.includes(href),
                                    'cursor-pointer': isOpen,
                                },
                                linkProps.className
                            )}
                        >
                            {label}
                        </ActionLink>
                    ))}
                </div>
            </div>
        </div>
    );
};
interface MobileMenuProps {
    className?: string;
    isLoggedIn?: Nullable<boolean>;
    firstLinkSet: Array<MainNavLinkType>;
    secondLinkSet?: Array<MainNavLinkType>;
    onMobileMenuOpenClose?: (isOpen: boolean) => void;
}

const MobileMenu = ({
    firstLinkSet,
    secondLinkSet,
    isLoggedIn = false,
    className,
    onMobileMenuOpenClose,
}: MobileMenuProps) => {
    const pathname = usePathname();
    const { width } = useWindowSize();
    const [showNavigation, setShowNavigation] = useState(false);
    const toggleNavigation = () => {
        const newShowState = !showNavigation;
        setShowNavigation(newShowState);
        onMobileMenuOpenClose?.(newShowState);
    };
    const closeNavigation = useCallback(() => {
        setShowNavigation(false);
        onMobileMenuOpenClose?.(false);
    }, [onMobileMenuOpenClose]);

    useEffect(() => {
        if (width > 1024) {
            closeNavigation();
        }
    }, [closeNavigation, width]);

    useEffect(() => {
        closeNavigation();
    }, [pathname, closeNavigation]);

    return (
        <>
            <menu className={cn('relative lg:hidden', className)}>
                <IconButton
                    iconProps={{
                        className: 'w-6 h-full text-white flex items-center justify-center',
                        iconClassName: 'w-full',
                    }}
                    onClick={toggleNavigation}
                    onTouchEnd={toggleNavigation}
                    iconType="hamburger"
                    tabIndex={2}
                />
            </menu>

            <aside
                className={cn(
                    'lg:hidden lg:z-0 fixed top-0 right-0 z-1000 w-screen bg-analyst-black h-screen py-8 translate-x-full transition-transform duration-300 flex flex-col items-start',
                    {
                        'translate-x-0 transition-transform duration-300': showNavigation,
                    }
                )}
            >
                <header className="flex items-center justify-between w-full mb-10 px-10">
                    <ThematicLogoLink
                        tabIndex={1}
                        className="w-4"
                    />
                    <IconButton
                        iconProps={{
                            className: 'w-5 h-full text-white flex items-center justify-center',
                            iconClassName: 'w-full',
                        }}
                        className="self-end"
                        onClick={closeNavigation}
                        onTouchEnd={closeNavigation}
                        iconType="close"
                        tabIndex={2}
                    />
                </header>

                <div className="px-10 mb-10">
                    {isLoggedIn ? (
                        <UserProfileDropdown
                            className="max-h-12"
                            menuClassName="!right-auto !left-0"
                            toggleClassName="text-analyst-white"
                        />
                    ) : !isLoggedIn ? (
                        <SignupLoginButtonModal />
                    ) : null}
                </div>

                <nav className="w-full">
                    <div
                        className={cn('flex flex-col gap-8 px-10', {
                            'border-b border-analyst-gray pb-10 mb-10': secondLinkSet,
                        })}
                    >
                        {firstLinkSet.map((linkProps, index: number) => {
                            if ((linkProps as AccordionNavLinkProps).subMenu) {
                                const accordionLinkProps = linkProps as AccordionNavLinkProps;

                                return (
                                    <AccordionMenu
                                        key={`first-set-tab-navigation-link-${index}`}
                                        label={linkProps.label}
                                        subMenuItems={accordionLinkProps.subMenu}
                                        onLinkClick={closeNavigation}
                                    />
                                );
                            }

                            const subMenuNavLinkProps = linkProps as SubMenuLinkProps;

                            return (
                                <MainNavLink
                                    key={`mobile-global-navigation-link-${index}`}
                                    {...linkProps}
                                    href={subMenuNavLinkProps.href}
                                >
                                    {subMenuNavLinkProps.label}
                                </MainNavLink>
                            );
                        })}
                    </div>
                    {secondLinkSet && (
                        <div className="flex flex-col gap-8 px-10">
                            {secondLinkSet.map((linkProps, index: number) => {
                                if ((linkProps as AccordionNavLinkProps).subMenu) {
                                    const accordionLinkProps = linkProps as AccordionNavLinkProps;

                                    return (
                                        <AccordionMenu
                                            key={`first-set-tab-navigation-link-${index}`}
                                            label={linkProps.label}
                                            subMenuItems={accordionLinkProps.subMenu}
                                        />
                                    );
                                }

                                const subMenuNavLinkProps = linkProps as SubMenuLinkProps;

                                return (
                                    <MainNavLink
                                        key={`mobile-global-navigation-link-${index}`}
                                        {...linkProps}
                                        href={subMenuNavLinkProps.href}
                                    >
                                        {subMenuNavLinkProps.label}
                                    </MainNavLink>
                                );
                            })}
                        </div>
                    )}
                </nav>
            </aside>
        </>
    );
};

export const GlobalNavigationBar: React.FC = () => {
    const { globalState } = useGlobalState();
    const { currentUser, currentUserLoading } = globalState;
    const [isMobileMenuOpen, setIsMobileMenuOpenState] = useState(false);
    const [isVisible, setIsVisibleState] = useState(true);
    const { isScrolling, y, lastY } = useScrollListener();
    const firstLinkSet = useMemo(() => MAIN_NAV_LINKS.slice(0, 2), []) as Array<MainNavLinkType>;
    const secondLinkSet = useMemo(() => MAIN_NAV_LINKS.slice(2), []) as Array<MainNavLinkType>;
    const isLoggedIn = currentUser && !currentUserLoading;

    useEffect(() => {
        if (isScrolling) {
            if (lastY > y && !isVisible) {
                setIsVisibleState(true);
            } else if (lastY < y && isVisible) {
                setIsVisibleState(false);
            }
        }
    }, [isScrolling, isVisible, lastY, y]);

    return (
        <div
            className={cn(
                'fixed left-1/2 top-6 -translate-x-1/2 z-100 w-full px-4 md:px-8 flex justify-center items-center',
                {
                    'top-0': isMobileMenuOpen,
                    'top-0 -translate-y-full transition-all': !isVisible,
                    'top-12': isLoggedIn && currentUser.isThematicAdmin && isVisible && !isMobileMenuOpen,
                    'translate-y-0 transition-all': isVisible,
                }
            )}
        >
            <nav
                className={cn(
                    'w-full max-w-xl justify-between lg:justify-start py-3 lg:py-0 animate-fadeIn bg-analyst-black rounded-full px-4 lg:pr-8 lg:pl-6 flex items-center lg:gap-6 xl:gap-8 max-h-12 lg:max-w-none lg:w-auto'
                )}
            >
                <ThematicLogoLink
                    className="block h-6"
                    iconInnerWrapperClassName="w-auto flex items-center"
                    tabIndex={1}
                />
                <div className="pr-4 lg:pr-6 xl:pr-8 border-r border-analyst-dark-lavender py-3 hidden lg:flex lg:items-center lg:gap-6 xl:gap-8">
                    {firstLinkSet.map((linkProps, index: number) => {
                        if ((linkProps as AccordionNavLinkProps).subMenu) {
                            const accordionLinkProps = linkProps as AccordionNavLinkProps;

                            return (
                                <DropdownMenu
                                    key={`global-navigation-link-${index}`}
                                    iconType={accordionLinkProps.iconType as keyof typeof IconMap}
                                    trigger={accordionLinkProps.label}
                                    menuLinks={accordionLinkProps.subMenu as Array<MenuLinkProps>}
                                />
                            );
                        }

                        const subMenuNavLinkProps = linkProps as SubMenuLinkProps;

                        return (
                            <MainNavLink
                                key={`global-navigation-link-${index}`}
                                {...subMenuNavLinkProps}
                            >
                                {subMenuNavLinkProps.label}
                            </MainNavLink>
                        );
                    })}
                </div>
                <div className="hidden lg:flex lg:items-center py-3 lg:gap-6 xl:gap-8">
                    {secondLinkSet.map((linkProps, index: number) => {
                        if ((linkProps as AccordionNavLinkProps).subMenu) {
                            const accordionLinkProps = linkProps as AccordionNavLinkProps;

                            return (
                                <DropdownMenu
                                    key={`global-navigation-link-${index}`}
                                    trigger={accordionLinkProps.label}
                                    menuLinks={accordionLinkProps.subMenu as Array<MenuLinkProps>}
                                />
                            );
                        }

                        const subMenuNavLinkProps = linkProps as SubMenuLinkProps;

                        return (
                            <MainNavLink
                                key={`global-navigation-link-${index}`}
                                {...subMenuNavLinkProps}
                            >
                                {subMenuNavLinkProps.label}
                            </MainNavLink>
                        );
                    })}
                </div>

                {!globalState.currentUserLoading && !globalState.currentUser && (
                    <SignupLoginButtonModal
                        tabIndex={9}
                        className={cn('animate-fadeIn hidden opacity-0 md:block md:opacity-100 max-h-12')}
                        buttonClassName="bg-opacity-100 p-0 border-0 bg-transparent text-analyst-white text-base hover:bg-transparent rounded-none hover:text-analyst-lavender"
                    />
                )}

                <MobileMenu
                    isLoggedIn={isLoggedIn}
                    firstLinkSet={firstLinkSet}
                    secondLinkSet={secondLinkSet}
                    onMobileMenuOpenClose={setIsMobileMenuOpenState}
                />
            </nav>

            {globalState.currentUser && !globalState.currentUserLoading && (
                <UserProfileDropdown
                    className={cn(
                        'hidden animate-fadeIn opacity-0 lg:flex lg:opacity-100 absolute top-1/2 z-10 -translate-y-1/2 right-10'
                    )}
                />
            )}
        </div>
    );
};

export const ProductOnlyGlobalNavigationBar: React.FC = () => {
    const { globalState } = useGlobalState();
    const { currentUser, currentUserLoading } = globalState;
    const [isVisible, setIsVisibleState] = useState(true);
    const { isScrolling, y, lastY } = useScrollListener();
    const firstLinkSet = useMemo(() => MAIN_NAV_LINKS.slice(0, 2), []) as Array<MainNavLinkType>;
    const secondLinkSet = useMemo(() => MAIN_NAV_LINKS.slice(2), []) as Array<MainNavLinkType>;
    const isLoggedIn = currentUser && !currentUserLoading;

    useEffect(() => {
        if (isScrolling) {
            if (lastY > y && !isVisible) {
                setIsVisibleState(true);
            } else if (lastY < y && isVisible) {
                setIsVisibleState(false);
            }
        }
    }, [isScrolling, isVisible, lastY, y]);

    return (
        <div className={cn('bg-analyst-black w-full px-4 py-5 md:px-8 lg:px-10 lg:py-7 flex items-center')}>
            <nav className={cn('w-full animate-fadeIn flex items-center gap-6 xl:gap-8')}>
                <ThematicLogoLink
                    className="block h-6"
                    iconInnerWrapperClassName="w-auto flex items-center"
                    tabIndex={1}
                />

                <div className="pr-4 lg:pr-6 xl:pr-8 border-r border-analyst-dark-lavender py-3 hidden lg:flex lg:items-center lg:gap-6 xl:gap-8">
                    {firstLinkSet.map((linkProps, index: number) => {
                        if ((linkProps as AccordionNavLinkProps).subMenu) {
                            const accordionLinkProps = linkProps as AccordionNavLinkProps;

                            return (
                                <DropdownMenu
                                    key={`global-navigation-link-${index}`}
                                    iconType={accordionLinkProps.iconType as keyof typeof IconMap}
                                    trigger={accordionLinkProps.label}
                                    menuLinks={accordionLinkProps.subMenu as Array<MenuLinkProps>}
                                />
                            );
                        }

                        const subMenuNavLinkProps = linkProps as SubMenuLinkProps;

                        return (
                            <MainNavLink
                                key={`global-navigation-link-${index}`}
                                {...subMenuNavLinkProps}
                            >
                                {subMenuNavLinkProps.label}
                            </MainNavLink>
                        );
                    })}
                </div>
                <div className="hidden lg:flex lg:items-center py-3 lg:gap-6 xl:gap-8">
                    {secondLinkSet.map((linkProps, index: number) => {
                        if ((linkProps as AccordionNavLinkProps).subMenu) {
                            const accordionLinkProps = linkProps as AccordionNavLinkProps;

                            return (
                                <DropdownMenu
                                    key={`global-navigation-link-${index}`}
                                    trigger={accordionLinkProps.label}
                                    menuLinks={accordionLinkProps.subMenu as Array<MenuLinkProps>}
                                />
                            );
                        }

                        const subMenuNavLinkProps = linkProps as SubMenuLinkProps;

                        return (
                            <MainNavLink
                                key={`global-navigation-link-${index}`}
                                {...subMenuNavLinkProps}
                            >
                                {subMenuNavLinkProps.label}
                            </MainNavLink>
                        );
                    })}
                </div>

                {globalState.currentUser && !globalState.currentUserLoading ? (
                    <UserProfileDropdown
                        className={cn('hidden opacity-0 lg:flex lg:opacity-100 ml-auto')}
                        toggleClassName="text-analyst-white"
                    />
                ) : !globalState.currentUserLoading ? (
                    <SignupLoginButtonModal
                        tabIndex={9}
                        className={cn('hidden opacity-0 lg:block lg:opacity-100 ml-auto')}
                    />
                ) : null}

                <MobileMenu
                    isLoggedIn={isLoggedIn}
                    firstLinkSet={firstLinkSet}
                    secondLinkSet={secondLinkSet}
                    className="ml-auto"
                />
            </nav>
        </div>
    );
};
