import { LoginSignupForm, OpenWithTypes } from '@/components/forms/login-signup-form';
import BaseModal from '@/components/global/base-modal';
import { CurrentUserType } from '@/queries/current-user';

type LoginSignupModalProps = {
    isOpen: boolean;
    closeFn: () => void;
    signUpCopy?: React.ReactNode;
    logInCopy?: React.ReactNode;
    openWith?: OpenWithTypes;
    onLoginSuccess?: (user: CurrentUserType) => void;
};

export const LoginSignupModal = ({
    closeFn,
    isOpen,
    signUpCopy,
    logInCopy,
    onLoginSuccess,
    openWith = OpenWithTypes.SIGNUP,
}: LoginSignupModalProps) => {
    return (
        <BaseModal
            isOpen={isOpen}
            onRequestClose={closeFn}
            headerClassName="text-center text-3xl"
            className="rounded-lg border border-analyst-dark-lavender shadow-lg pt-4 bg-gradient-to-b to-analyst-lavender from-10% from-analyst-white max-w-xl"
            size="1/2"
        >
            <div className="px-8 pb-8">
                <LoginSignupForm
                    {...{
                        logInCopy,
                        onLoginSuccess,
                        openWith,
                        signUpCopy,
                    }}
                />
            </div>
        </BaseModal>
    );
};
