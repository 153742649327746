import { gql } from 'graphql-request';
import { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';

import { FieldError, Label, SubmitButton, TextInput } from '@/components/dom/form-elements';
import TOSMessaging from '@/components/global/tos-messaging';
import { GQL_CLIENT } from '@/lib/graphql';
import * as gtag from '@/lib/gtag';

const SIGNUP_QUERY = gql`
    mutation createUserAndOrganization($email: String!, $name: String!, $inviteId: ID) {
        createUserAndOrganization(input: { email: $email, name: $name, inviteId: $inviteId }) {
            __typename
            ... on CreateUserSuccess {
                userId
                loginAttemptId
            }
            ... on FieldErrors {
                errors {
                    field
                    message
                }
            }
        }
    }
`;

interface SignupFormProps {
    onSuccess?: (loginAttemptId: string, email: string) => void;
    initialEmail?: string;
    inviteId?: string;
}

export const SignupForm = ({ onSuccess, initialEmail = '', inviteId }: SignupFormProps) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [email, setEmail] = useState(initialEmail);
    const [emailError, setEmailError] = useState('');
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const onEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setEmailError('');
    };
    const onNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameError('');
    };

    const submitSignUpForm = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);

        const variables = { email, inviteId, name };
        const data: {
            createUserAndOrganization:
                | {
                      __typename: 'FieldErrors';
                      errors: Array<{
                          field: string;
                          message: string;
                      }>;
                  }
                | {
                      __typename: 'CreateUserSuccess';
                      userId: Optional<string>;
                      loginAttemptId: Optional<string>;
                  };
        } = await GQL_CLIENT.request(SIGNUP_QUERY, variables);
        const result = data.createUserAndOrganization;

        const success = result.__typename === 'CreateUserSuccess';

        if (success) {
            onSuccess?.(result.loginAttemptId || '', email);
        } else {
            result.errors.forEach((value: { field: string; message: string }) => {
                if (value.field === 'email_address') {
                    setEmailError(value.message);
                } else if (value.field === 'name') {
                    setNameError(value.message);
                } else {
                    toast.error(value.message);
                }
            });
        }

        gtag.event({
            action: success ? 'Success' : 'Error',
            category: 'Sign Up',
            label: 'Submit',
        });

        setIsSubmitting(false);
    };

    return (
        <form
            onSubmit={submitSignUpForm}
            noValidate
        >
            <div className="mb-6">
                <Label
                    text="Email"
                    labelCopyClassName="text-left text-analyst-darker-gray font-brand-md mb-2 text-base"
                >
                    <TextInput
                        className="w-full"
                        fieldClassName="w-full rounded-full px-3 py-2"
                        value={email}
                        onChange={onEmailInput}
                        required={true}
                        spellCheck={false}
                        placeholder="someonecool@yourorganization.com"
                        autoFocus={true}
                    />
                </Label>
                <FieldError message={emailError} />
            </div>
            <div className="mb-6">
                <Label
                    text="Name"
                    labelCopyClassName="text-left text-analyst-darker-gray font-brand-md mb-2 text-base"
                >
                    <TextInput
                        className="w-full"
                        fieldClassName="w-full rounded-full px-3 py-2"
                        value={name}
                        onChange={onNameInput}
                        required={true}
                        spellCheck={false}
                        placeholder="First and last name"
                        testId="sign-up-name"
                    />
                </Label>
                <FieldError message={nameError} />
            </div>
            <TOSMessaging clickedString="Send one-time passcode" />
            <SubmitButton
                isDisabled={isSubmitting}
                value="Send one-time passcode"
                roundedCorners="full"
                className="font-brand-md mx-0 w-full"
                color="primary-gradient"
            />
        </form>
    );
};
