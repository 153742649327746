// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <T extends (...args: Array<any>) => void>(func: T, wait: number) => {
    let timeout: null | ReturnType<typeof setTimeout> = null;
    return (...args: Parameters<T>) => {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(self, args);
        }, wait);
    };
};
